import React, {useState} from "react";
import styles from "./hows.module.scss";

const HowItWorks = () => {
    const [hows] = useState([
        {
            icon: "/assets/Frame1.svg",
            title: "Choose what you need",
        },
        {
            icon: "/assets/Frame2.svg",
            title: "Answer a few simple questions",
        },
        {
            icon: "/assets/Frame3.svg",
            title: "Get fast approval from our doctors",
        },
        {
            icon: "/assets/Frame4.svg",
            title: "Receive your treatment",
        },
        {
            icon: "/assets/refill.svg",
            title: "Get your refill automatically",
        },
    ]);
    return (
        <section className={styles.how}>
            <h2 className={`${styles.section_header} ${styles.how_it_work}`}>How it works:</h2>
            <div className={styles.how_items}>
                {hows.map((how, idx) => (
                    <div key={idx} className={styles.how_item}>
                        <img src={how.icon} alt="icon" className={styles.how_icon}/>
                        <h4 className={styles.how_title}>{how.title}</h4>
                        <img className={styles.how_vector} src="/assets/vector_1.png" alt="vector"/>
                        <img className={styles.how_vector_mobile} src="/assets/vector_2.png" alt="vector"/>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default HowItWorks;
