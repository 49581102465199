import React, {useState} from "react";
import Faq from "./Faq";

import styles from './faq.module.scss';

const FAQ = () => {
    const [faqs, setFaqs] = useState([
        {
            open: false,
            question: "What is upfor.love?",
            answers: [
                'upfor.love is dedicated to helping men take control of their sexual well-being. We connect men with US Board-Certified Physicians to ensure they receive the treatment they need quickly, easily, and discreetly.'
            ]
        },
        {
            open: false,
            question: "How does upfor.love work?",
            answers: [
                'Products available through the upfor.love site can only be purchased with a prescription. All prescription medication orders placed through upfor.love are reviewed by a medical provider, with whom customers are connected online once their order is submitted. The medical provider will perform a quick but thorough evaluation to determine whether a prescription is appropriate.',
                'Your order will be quickly delivered to the shipping address you provided in discreet packaging once your provider completes your evaluation.',
                'If your provider determines a prescription is not appropriate for you, they will provide you with information about next steps.'
            ]
        },
        {
            open: false,
            question: "What type of treatments do you provide?",
            answers: [
                'We currently offer Sildenafil (Generic Viagra®) 50mg, 100mg; Tadalafil (Generic Cialis®) 5 mg, 10mg, 20mg; and Sertraline 50mg.',
                'These medications are only available with a doctor’s prescription that can be provided after finishing our medical questionnaire and consulting with one of our US Board-Certified Physicians.'
            ]
        },
        {
            open: false,
            question: "Is this service discreet?",
            answers: [
                'Yes, we provide 100% discreet packaging, shipping, and delivery.'
            ]
        },
        {
            open: false,
            question: "Who can use this service?",
            answers: [
                'upfor.love products are only for biological males and recommended for ages 21+'
            ]
        },
    ]);
    const toggleFaq = (index) => {
        const mapped = faqs.map((faq, idx) => {
            if (idx === index) {
                faq.open = !faq.open;
            } else {
                faq.open = false;
            }
            return faq;
        });
        setFaqs(mapped);
    };

    const faqList = faqs.map((faq, idx) => {
        return <Faq faq={faq} key={idx} index={idx} toggleFaq={toggleFaq}/>;
    });
    return (
        <section className={styles.faqContainer}>
            <h2 className={`${styles.section_header} ${styles.faq_header}`}>Have any questions?</h2>
            <div className={styles.faqList}>{faqList}</div>
            {/*<a href="https://support.upfor.love" className={`btn-secondary ${styles.faq_button}`} target="_blank"*/}
            {/*   rel="noreferrer">*/}
            {/*    More questions? Click here.*/}
            {/*</a>*/}
        </section>
    )
}

export default FAQ;
