import React, { useContext, useEffect, useState } from 'react'
import Link from 'next/link';

import styles from "./index.module.scss";
import { fetchProductList } from '../utils/router'
import { ProductContext } from '../components/providers/ProductProvider'
import { UserContext } from '../components/providers/UserProvider'
import FaqList from '../components/Faq/FaqList';
import HowItWorks from "../components/HowItWorks/HowItWorks";

const Homepage = ({ fallbackDrugs }) => {
    const [features] = useState([
        {
            icon: "/assets/free_shipping.svg",
            title: "Free Shipping For All Products",
        },
        {
            icon: "/assets/packaging.svg",
            title: "Discreet packaging and delivery",
        },
        {
            icon: "/assets/treatment_plan.svg",
            title: "FDA-Approved Generic, 90% Cheaper Than Brand Name",
        },
        {
            icon: "/assets/certified_physicians.svg",
            title: "US Board-Certified Physicians",
        },
    ]);
    const [products, setProducts] = useState(fallbackDrugs ?? [])
    const { allProducts, setProductContextValue } = useContext(ProductContext)
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (!allProducts.length) return
        const drugs = [...new Map(allProducts.map(item =>
          [item.drug, item])).values()];
        const filteredDrugs = drugs.filter(drug => drug.type !== 2)
        setProducts(filteredDrugs)
    }, [allProducts])

    const [feedbacks] = useState([
        {
            icon: "/assets/UserIcon.svg",
            name: "John",
            text: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        },
        {
            icon: "/assets/UserIcon.svg",
            name: "John",
            text: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        },
        {
            icon: "/assets/UserIcon.svg",
            name: "John",
            text: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        },
    ]);

    return (
        <div className={styles.homepageContainer}>
            {
                !!user || <section className={styles.trial}>
                    <div className={styles.trial_text}>
                        <h3 className={styles.trial_subtitle}>100% Satisfaction Guaranteed</h3>
                        <h2 className={styles.trial_title}>Try One Month Risk-Free!</h2>
                        <p className={styles.trail_description}>
                            If you&apos;re not happy with the improvement in your sexual performance you can cancel your plan for a full refund, minus the doctor&apos;s consultation fee.
                        </p>
                        <Link passHref href="/questionnaire/cta">
                            <a className={`btn-primary ${styles.trial_button}`} onClick={() => { setProductContextValue(ctx => ({ ...ctx, isPromotional: true })); localStorage.setItem('isPromotional', 'true')}}>
                                Start your trial
                            </a>
                        </Link>
                    </div>
                    <img src="/assets/Spaceman_3.png" alt="spacemen" className={styles.trial_img} />
                </section>
            }
            <HowItWorks />
            <section className={styles.homepage_banner}>
                <div className={styles.homepage_banner_title}>
                    <h1>Ready to invest in your 🍆?</h1>
                    <p>
                        Quick, easy, and discreet treatment for the most important organ in your body.
                    </p>
                    <Link passHref href="/questionnaire/cta">
                        <a className="btn-primary">Find my treatment</a>
                    </Link>
                </div>

                <div className={styles.homepage_banner_logo}>
                    <img src="/assets/spaceman.png" alt="spaceman" className={styles.spaceman}/>
                </div>
            </section>
            <section className={styles.homepage_types}>
                <div className="card with-hover">
                    <Link passHref href="/products/erectile-disfunction">
                        <div className={styles.homepage_type}>
                            <h2 className={styles.homepage_type_title}>
                                Erectile <br/> Dysfunction
                            </h2>
                            <img src="/pills/ED-treatment.png" className={styles.homepage_type_image} alt="pill bottle"/>
                        </div>
                    </Link>
                </div>
                <div className="card with-hover">
                    <Link passHref href={"/products/premature-ejaculation"}>
                        <div className={styles.homepage_type}>
                            <h2 className={styles.homepage_type_title}>
                                Premature <br/> Ejaculation
                            </h2>
                            <img src="/pills/PE-treatment.png" className={styles.homepage_type_image} alt="pill bottle"/>
                        </div>
                    </Link>
                </div>
            </section>
            <section className={styles.features}>
                <img src="/assets/Spaceman_2.png" alt="Spaceman" className={styles.features_img}/>
                <div className={styles.features_list}>
                    {features.map((feature, idx) => (
                        <div className={styles.feature_item} key={idx}>
                            <img src={feature.icon} alt="icon" className={styles.feature_icon}/>
                            <h4 className={styles.feature_title}>{feature.title}</h4>
                        </div>
                    ))}
                </div>
            </section>
            <section className={styles.products}>
                <div className="products_header">
                    <h1 className={styles.section_header}>Making sure you are upfor.<span
                        className="red-text">love</span></h1>
                    <p className={styles.section_sub_header}>
                        Our products are designed to improve your sex life.
                    </p>
                </div>
                <div className="product_slider">
                    <div className="product_list">
                        {products.map((product, idx) => (
                            <div className="product_item card with-hover" key={idx}>
                                <div className="product_info">
                                    <img src={product.icon} alt="Product" className="product_icon"/>
                                    <h4 className="product_title">{product.drugName || product.drug}</h4>
                                    {product.subTitle && (
                                        <p className="product_sub_title">{product.subTitle}</p>
                                    )}
                                    <p className="product_description short">{product.shortDescription}</p>
                                </div>
                                <Link passHref href={`/products/${product.visitType}/${product.drug}`}>
                                    <a className="product_link btn-primary">Learn more</a>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            {/* <section className={styles.bundle}>*/}
            {/*     <div className={styles.bundle_image}>*/}
            {/*         <img src="/assets/bundle.png" alt="Tadalafil and Sertraline"/>*/}
            {/*     </div>*/}
            {/*     <div className={styles.bundle_content}>*/}
            {/*         <h4 className={styles.bundle_content_title}>Take control of your 🍆</h4>*/}
            {/*         <p className={styles.bundle_content_text}>*/}
            {/*             Using <span className="fw-bold">Tadalafil and Sertraline</span> can treat both ED and PE. Tadalafil is a PDE5*/}
            {/*             inhibitor, increasing blood flow to the penis while Sertraline is an SSRI, increasing the serotonin you receive*/}
            {/*            to the brain. Both of these medications can be effective together in treating sexual dysfunction.*/}
            {/*        </p>*/}
            {/*        <Link passHref href="/bundle">*/}
            {/*            <a className="btn-primary small">Get your treatment</a>*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*</section>*/}
            {/*<div className="feedbacks">*/}
            {/*  <div className="feedbacks_header">*/}
            {/*    <h1 className="section_header">Our customers love what we do</h1>*/}
            {/*    <p className="section_sub_header">*/}
            {/*      Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet*/}
            {/*      sint. Velit officia consequat duis enim.*/}
            {/*    </p>*/}
            {/*    <a href="/questionnaire/cta" className="btn-primary">*/}
            {/*      Get started*/}
            {/*    </a>*/}
            {/*  </div>*/}
            {/*  <div className="feedbacks_list">*/}
            {/*    {feedbacks.map((feedback, idx) => (*/}
            {/*      <div className="feedback_item card" key={idx}>*/}
            {/*        <div className="feedback_header">*/}
            {/*          <div className="feedback_user">*/}
            {/*            <img src={feedback.icon} alt="User" />*/}
            {/*            <p className="feedback_username">{feedback.name}</p>*/}
            {/*          </div>*/}
            {/*          <img src="/assets/Quotes.svg" alt="Quotes" />*/}
            {/*        </div>*/}
            {/*        <div className="feedback_text">{feedback.text}</div>*/}
            {/*      </div>*/}
            {/*    ))}*/}
            {/*  </div>*/}
            {/*</div>*/}
            <FaqList />
            <div className={styles.contact}>
                <a href="mailto:talktous@upfor.love" className={`btn-secondary ${styles.faq_button}`} rel="noreferrer">
                    More questions? Contact us.
                </a>
            </div>
        </div>
    );
};

export async function getStaticProps () {
    const products = await fetchProductList()
    const drugs = [...new Map(products.map(item =>
      [item.drug, item])).values()];
    return {
        props: {
            fallback: {
                '/product/allProducts': products
            },
            fallbackDrugs: drugs
        }
    }
}

export default Homepage;

/*
ASSETS NEEDED:

checklist icon
person icon
box icon

medical box icon
calendar check icon
closed box icon

large screen list icon
large doctor icon
large moving van icon

orange faq down arrow icon

cc icons


NONFUNCTIONING:

Bottom of page ->
Contact us link
social media icons
email subscribe box






*/
