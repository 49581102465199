import React from "react";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown} from '@fortawesome/free-solid-svg-icons';

import styles from './faq.module.scss';

const Faq = (props) => {
    const {faq, index, toggleFaq} = props;

    const faqClass = faq.open ? `${styles.faq} ${styles.active}` : styles.faq;

    const handleFaqClick = () => {
        toggleFaq(index);
    };

    return (
        <div className={`${faqClass} cursor-p`} onClick={handleFaqClick}>
            <div className={styles.faqQuestion}>
                <span className={styles.faqQuestion__title}> {faq.question} </span>
                <span className={styles.faqQuestion__toggle}><FontAwesomeIcon icon={faAngleDown}/></span>
            </div>
            <ul className={styles.faqAnswer}>
                {faq.answers.map((answer, idx) => (
                    <li key={idx} className={styles.faqAnswer__item}>{answer}</li>
                ))}
            </ul>
        </div>
    );
};

export default Faq;
